import React from "react"
import styled from "styled-components"
import { buildClassNames, pxToRem, newPalette } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import Check from "@cbs-sports/sports-shared-client/build/cjs/components/icons/CircleCheck"
import constants from "../../../../../common/constants"

type PrizeLockStatus = "LOCKED" | "UNLOCKED"

interface PrizePromoItemProps {
  logoNode?: React.ReactNode
  status: PrizeLockStatus
  className?: string
}

const COPY_BY_STATUS: Record<PrizeLockStatus, string> = {
  LOCKED: `Enter both Men’s and Women’s Challenge for a chance to win an All-New ${constants.MARCH_MADNESS_SPORT_YEAR + 1} Nissan Armada®.`,
  UNLOCKED: `Congratulations! You're now entered for a chance to win an All-New ${constants.MARCH_MADNESS_SPORT_YEAR + 1} Nissan Armada®.`,
}

const PrizePromoItemRoot = styled.div`
  flex: 1;
  padding: 0 1.5rem 1.5rem;

  .item__container {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    padding-bottom: 0.5rem;
  }

  .item__wrapper--checkmark {
    flex: 1 0 auto;
  }

  .item__wrapper--prize {
    flex: 2 1 auto;
  }

  .item__wrapper--spacing {
    flex: 1 0 auto;
  }

  .item__checkmark {
    max-width: ${pxToRem(20)};
    max-height: ${pxToRem(20)};
    color: ${newPalette.gray90};
  }

  .item__checkmark--completed {
    color: ${newPalette.green};
  }

  .item__body {
    font-size: ${pxToRem(12)};
    line-height: ${pxToRem(18)};
    text-align: center;
    color: ${newPalette.gray40};
    letter-spacing: -0.1px;
  }
`

const PrizePromoItem: React.VFC<PrizePromoItemProps> = ({ status, logoNode, ...rest }) => {
  const body = COPY_BY_STATUS[status]

  return (
    <PrizePromoItemRoot {...rest}>
      <div className="item__container">
        <div className="item__wrapper--checkmark">
          <Check className={buildClassNames({ item__checkmark: true, "item__checkmark--completed": status === "UNLOCKED" })} />
        </div>
        <div className="item__wrapper--prize">{logoNode}</div>
        <div className="item__wrapper--spacing"></div>
      </div>
      <p className="item__body">{body}</p>
    </PrizePromoItemRoot>
  )
}

export default PrizePromoItem

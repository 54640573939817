import React, { useContext, useState } from "react"
import { useHistory, useLocation } from "react-router"
import { withoutDomain } from "../../../../utils/url-utils"
import UpsertEntryNameModal from "../../../PoolPages/components/EntryModals/UpsertEntryNameModal"
import { getParam, NCAAW_GAME_INSTANCE_UID } from "@cbs-sports/sports-shared-client/build/cjs/utils"
import PoolDataContex, { PoolDataContextType } from "../../../../Contexts/PoolDataContext"
import { getNumberOfChallengeEntries } from "../../../../hooks/useMainPromoInfo"
import { useDeviceType } from "../../../../Base/DeviceType"
import { isNCAAWTournamentMatcher } from "../../../../../common/common-utils-helpers"

type JoinChallengePromoItemProps = {
  isLoggedIn: boolean
  isFromLobby?: boolean
  isPreTournament?: boolean
  gameInstanceUid: string
  seasonType?: string
  seasonYear?: number
  productAbbrev?: string
}

const JoinChallengePromoItem: React.VFC<JoinChallengePromoItemProps> = ({
  isLoggedIn,
  isFromLobby = true,
  gameInstanceUid,
  isPreTournament,
  seasonType,
  seasonYear,
}) => {
  const location = useLocation()
  const deviceType = useDeviceType()
  const context = useContext<PoolDataContextType>(PoolDataContex)

  const numberOfEntries = getNumberOfChallengeEntries(context?.currentUser?.entries)
  const alreadyHasChallengeEntry = gameInstanceUid === NCAAW_GAME_INSTANCE_UID ? (numberOfEntries?.ncaaw ?? 0) > 0 : (numberOfEntries?.ncaab ?? 0) > 0

  const history = useHistory()
  const [showModal, setShowModal] = useState<boolean>(() => {
    if (!isLoggedIn || alreadyHasChallengeEntry) {
      return false
    }

    const modalOpen = getParam("join-challenge-modal", location.search)
    return modalOpen === "true"
  })

  const hideModal = (created = false, entryUrl: string | undefined = undefined) => {
    const { pathname } = location
    setShowModal(false)
    if (isFromLobby && created) {
      if (entryUrl) {
        if (deviceType === "handheld") {
          const roadblockUrl = `/games/roadblock?showCongrats=true&from=lobby&gameType=${gameInstanceUid}`
          history.push(withoutDomain(roadblockUrl))
        } else {
          history.push(withoutDomain(entryUrl))
        }
      }
    } else {
      history.replace(pathname)
    }
  }

  const isWomenBracket = isNCAAWTournamentMatcher.test(gameInstanceUid)

  return (
    <UpsertEntryNameModal
      isOpen={showModal}
      redirectToLobby
      title="Name Your Bracket"
      close={hideModal}
      gameInstanceUid={gameInstanceUid}
      isCommingSoon={isPreTournament}
      mode="bpc"
      seasonType={seasonType}
      year={seasonYear}
      productAbbrev={isWomenBracket ? "wbpc" : "bpc"}
    />
  )
}

export default JoinChallengePromoItem

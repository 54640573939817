import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { PoolSettingsTypesEnum } from "../../../../common/enums"
import { getInvitationUrl } from "../../../utils/url-utils"
import constants from "../../../../common/constants"
import { CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node } from "../../../../__generated__/CentralCurrentUsersEntriesQuery"
import PoolDataContext from "../../../Contexts/PoolDataContext"

const LobbyNeuronAd = () => {
  const location = useLocation()
  const poolData = React.useContext(PoolDataContext)

  useEffect(() => {
    let script: HTMLScriptElement | null = null
    script = document.createElement("script")
    script.src = `https://neuron${constants.APP_ENV === "dev" ? "-dev" : ""}.cbssports.cloud/client.js?platform=brackets-lobby`
    script.async = true
    document.body.appendChild(script)

    return () => {
      if (script) {
        document.body.removeChild(script)
      }
    }
  }, [location.pathname])

  const { currentUser, deviceType, myEntriesForArea, entryId, centralBracketState } = poolData || {}

  const { currentEntriesForArea: myEntries } = myEntriesForArea?.() || {}
  const bpmVisibleEntries = myEntries?.filter((entry) => !!entry.isVisible && !/challenge/.test(entry.gameInstanceUid))

  const uniquePoolEntries = bpmVisibleEntries?.reduce((acc, current) => {
    const poolId = current.pool?.id
    if (!acc.some((entry) => entry.pool?.id === poolId)) {
      acc.push(current)
    }
    return acc
  }, [] as CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node[])
  const neuronData = uniquePoolEntries?.map((entry) => {
    const { pool } = entry || {}
    const { poolSettings, membersCount, entriesCount, id: poolId } = pool || {}
    const openInvites = poolSettings?.__typename === PoolSettingsTypesEnum.LEGACY_POOL_SETTINGS && poolSettings.openInvites
    const maxEntriesPerUser = poolSettings?.__typename === PoolSettingsTypesEnum.LEGACY_POOL_SETTINGS && poolSettings.maxEntriesPerUser
    const poolInviteUrl = getInvitationUrl({ medium: "neuron", pool, entryId: entryId, hasManagerRole: true })
    const myEntriesForPool = myEntries?.filter((entry) => entry.pool?.id === poolId)?.length ?? 0
    const hasManagerRole = entry.roles.includes(constants.POOL_ROLES.MANAGER)
    return {
      poolName: pool?.name,
      hasManagerRole,
      myEntriesForPool,
      membersCount,
      entriesCount,
      maxEntriesPerUser,
      openInvites,
      poolInviteUrl,
    }
  })

  return (
    <div
      id="neuron-user-id"
      data-user-id={currentUser?.custId || 0}
      data-neuron-pool-data={JSON.stringify(neuronData)}
      data-neuron-bracket-data={JSON.stringify(centralBracketState)}
      style={{ minHeight: deviceType === "handheld" ? "200px" : "325px", position: "relative" }}
    />
  )
}

export default LobbyNeuronAd
